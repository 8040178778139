import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import React, { useEffect, useRef } from "react"
import { Col, Container, Row } from "react-bootstrap"
import video from "../../images/home/videoBackground.mp4"
import "./headerblue.scss"

const HeaderBlue = ({ className }) => {
  const { t } = useTranslation("index", { keyPrefix: "HeaderBlue" })
  const videoEl = useRef(null)
  const [isPaused, setIsPaused] = React.useState(false)

  const text = useRef(null)

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.log(text.current)
        console.log(error)
      })
  }

  const togglePause = () => {
    if (isPaused) {
      videoEl.current.play()
    } else {
      videoEl.current.pause()
    }
    setIsPaused(!isPaused)
  }

  useEffect(() => {
    attemptPlay()
  }, [])

  return (
    <section className={(className ? className + " " : "") + "headerblue"}>
      <Container fluid>
        <Row className="back-header videoSection">
          <video
            muted
            autoPlay
            playsInline
            loop
            className="vid_1"
            ref={videoEl}
          >
            <source src={video} type="video/mp4" />
          </video>
          <Col className="back-header-text-home" xs={12} sm={12} md={7} lg={7}>
            <span className="back-header-title" ref={text}>
              {t("We are Blue Trail Software")}
            </span>
            <p>
              {t(
                "A California benefit corporation, we want to be an agent of change in the way companies are managed today."
              )}{" "}
              <br></br>
              <br></br>
              {t(
                "And we also happen to excel at developing sophisticated software solutions."
              )}
            </p>
          </Col>

          <Col className="back-header-links-desk " md={12} lg={12}>
            <Col className="d-none d-lg-block" md={4} lg={3}>
              <div className="arrow">
                <a
                  href="#ourshowcase"
                  aria-label="Navigate to our showcase section"
                >
                  <span className="icon-arrow-down arw-white arw-large"> </span>
                </a>
              </div>
            </Col>
            <Col className="company-culture" md={4} lg={3}>
              <Link to="/about/">
                <Col className="img" md={12} lg={12}></Col>

                <Col className="text" md={12} lg={12}>
                  <span className="back-header-title">
                    {t("Company Culture")} <div className="Polygon-9"></div>{" "}
                  </span>
                  <br></br>
                  <span className="back-header-subtitle">
                    {t("Learn more")}{" "}
                  </span>
                </Col>
              </Link>
            </Col>
            <Col className="our-offer" md={4} lg={3}>
              <Link to="/services/">
                <Col className="img" md={12} lg={12}></Col>

                <Col className="text" md={12} lg={12}>
                  <span className="back-header-title">
                    {t("Our Offer")} <div className="Polygon-9"></div>{" "}
                  </span>
                  <br></br>
                  <span className="back-header-subtitle">{t("Discover")} </span>
                </Col>
              </Link>
            </Col>
            <Col className="education" md={4} lg={3}>
              <Link to="/education/">
                <Col className="img" md={12} lg={12}></Col>
                <Col className="text" md={12} lg={12}>
                  <span className="back-header-title">
                    {t("Education")} <div className="Polygon-9"></div>{" "}
                  </span>
                  <br></br>
                  <span className="back-header-subtitle">{t("Explore")}</span>
                </Col>
              </Link>
            </Col>
          </Col>
        </Row>
        <Row className="back-header-links" xs={12} sm={12} md={12} lg={12}>
          <Col className="company-culture" xs={12} sm={12} md={4} lg={4}>
            <Col className="img" xs={12} sm={12} md={12} lg={12}></Col>
            <Col className="text" xs={12} sm={12} md={12} lg={12}>
              <Link to="/about/">
                <span className="back-header-title">
                  {t("Company Culture")} <div className="Polygon-9"></div>{" "}
                </span>
                <br></br>
                <span className="back-header-subtitle">
                  {" "}
                  {t("Learn more")}{" "}
                </span>
              </Link>
            </Col>
          </Col>
          <Col className="our-offer" xs={12} sm={12} md={4} lg={4}>
            <Col className="img" xs={12} sm={12} md={12} lg={12}></Col>
            <Col className="text" xs={12} sm={12} md={12} lg={12}>
              <Link to="/services/">
                <span className="back-header-title">
                  {t("Our Offer")} <div className="Polygon-9"></div>{" "}
                </span>
                <br></br>
                <span className="back-header-subtitle"> {t("Discover")} </span>
              </Link>
            </Col>
          </Col>
          <Col className="education" xs={12} sm={12} md={4} lg={4}>
            <Col className="img" xs={12} sm={12} md={12} lg={12}></Col>
            <Col className="text" xs={12} sm={12} md={12} lg={12}>
              <Link to="/education/">
                <span className="back-header-title">
                  {t("Education")} <div className="Polygon-9"></div>{" "}
                </span>
                <br></br>
                <span className="back-header-subtitle"> {t("Explore")} </span>
              </Link>
            </Col>
          </Col>
        </Row>
        <div className="pause-button-container-blue">
          <button
            aria-label={isPaused ? "Resume" : "Pause"}
            className={`pause-button-blue ${isPaused ? "paused" : ""}`}
            onClick={e => {
              e.stopPropagation()
              togglePause()
            }}
          >
            {isPaused ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polygon points="9 17 15 12 9 7 9 17" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x="6" y="4" width="4" height="16" />
                <rect x="14" y="4" width="4" height="16" />
              </svg>
            )}
          </button>
        </div>
      </Container>
    </section>
  )
}

export default HeaderBlue
