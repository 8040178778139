import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./ourshowcase.scss"

const ShowCase = ({ className }) => {
  const { t } = useTranslation("index", { keyPrefix: "ShowCase" })
  return (
    <section
      id="ourshowcase"
      className={(className ? className + " " : "") + "our-showcase"}
    >
      <Container fluid="xl">
        <Row>
          <Col className="" xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="max-ourcase">
              <span
                className="
    our-showcase-title header-square blue md-large lg-large"
              >
                {t("Our Showcase")}
              </span>
              <br></br>
              <br></br>
              <div className="our-showcase-subtitle">
                {t(
                  "We work with all types of clients, small and large organizations.  We would like to showcase here the work done for a Fortune 100 company (Samsung), a midsize company (Cartier), and a startup (FuturePay)."
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ShowCase
