import React, { useRef, useEffect } from "react"
import { Container, Col, Row, Button } from "react-bootstrap"
import "./projects.scss"
import videoCartier from "../../images/home/cartierVideo.mp4"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"

const Projects = ({ className, previous }) => {
  const videoEl = useRef(null)
  const { t } = useTranslation("index", { keyPrefix: "Projects" })
  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    attemptPlay()
  }, [])

  return (
    <section className={(className ? className + " " : "") + "projects"}>
      <Container fluid="xl" className="max-container">
        <Row className="justify-content-xxl-center parent-div">
          <Col
            className="projects-div smart-things"
            xxl={4}
            xl={4}
            lg={4}
            md={4}
            sm={4}
          >
            <div className="logo-smart-things">
              <div className="img-logo-smart-things"></div>
              <p className="logo-title-smart-things">
                SmartThings <br></br>
                <span className="logo-subtitle">Powered by Samsung</span>
              </p>
            </div>
            <div className="phone img-smart-things"> </div>

            <div className="phone img-smart-things-2"> </div>

            <div className="text-smart-things">
              <p className="text-cases">
                {t(
                  "Samsung's agnostic IoT platform that transforms our homes into ecosystems of smart living solutions."
                )}
              </p>
              <Link
                to={"/casesmarthings/"}
                state={{ from: previous }}
                className="button-casestudy btn btn-light"
              >
                {t("Case study")}
              </Link>
            </div>
          </Col>
          <Col
            className="projects-div cartier"
            xxl={4}
            xl={4}
            lg={4}
            md={4}
            sm={4}
          >
            <div className="logo-cartier">
              <div className="img-logo-cartier"></div>
            </div>
            <div className="tablet img-cartier">
              <div className="gradientBackground">
                {/* <div className="frames">
                                    <video 
                                    muted 
                                    autoPlay 
                                    playsInline 
                                    loop 
                                    className="vid_1"
                                    ref={videoEl}
                                    >
                                        <source src={videoCartier} type="video/mp4" />
                                    </video>
                                </div> */}

                <div className="frames">
                  <video
                    muted
                    autoPlay
                    playsInline
                    loop
                    className="vid_1"
                    ref={videoEl}
                  >
                    <source src={videoCartier} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
            <div className="text-cartier">
              <p className="text-cases">
                {t(
                  "An application that dramatically enhances the customer experience when visiting Cartier stores to buy jewelry"
                )}
              </p>
              {/* <Link to={'/casecartier/'} state={{from:previous}}><Button className="button-casestudy" variant="light">Case study</Button></Link> */}
            </div>
          </Col>
          <Col
            className="projects-div future-pay"
            xxl={4}
            xl={4}
            lg={4}
            md={4}
            sm={4}
          >
            <div className="logo-future-pay">
              <div className="img-logo-future-pay"></div>
              <p className="logo-title-future-pay">
                FuturePay <br></br>
              </p>
            </div>

            <div className="phone img-future-pay"> </div>

            <div className="phone img-future-pay-2"> </div>

            <div className="text-future-pay">
              <p className="text-cases">
                {t(
                  "Future Pay – Put it in MyTab™ is a frictionless online payment option that lets customers buy now and pay later, with no credit card or hidden fees."
                )}
              </p>
              <Link
                to={"/casefuturepay/"}
                state={{ from: previous }}
                className="button-casestudy btn btn-light"
              >
                {t("Case study")}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Projects
